import { useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { redirect, push } from 'redux-first-router';
import { useTracking } from 'react-tracking';
import DocumentHeader from '@/components/DocumentHeader';
import SkyscraperBackground from '@/components/SkyscraperBackground';
import type { Method } from '@/components/AuthenticationSelect';
import { AuthenticationSelect } from '@/components/AuthenticationSelect';
import {
  ROUTE_WELCOME,
  ROUTE_USER_REGISTER,
  ROUTE_USER_REGISTER_LOCALE,
  ROUTE_WELCOME_LOCALE,
} from '@/constants/routes';
import { useMount } from '@simplywallst/ui-core';

import { WelcomeWrapper, Heading } from './styled';
import { clearAllCookies } from '@/utilities/cookies';
import { getActionFromPath, useLocation } from '@/hooks/useLocation';
import { useTranslation } from '@/hooks/useTranslation';

export const WelcomePage = () => {
  const { Track, trackEvent } = useTracking({
    type: 'track',
    page: 'Welcome',
    subject: 'page',
  });

  const queryClient = useQueryClient();
  useMount(() => {
    trackEvent({
      action: 'landed',
    });
    queryClient.clear();
    clearAllCookies();
  });

  const location = useLocation();

  const r = location.query?.r;

  const type = useSelector<{ location: { type: string } }, Method>((state) => {
    switch (state.location.type) {
      case ROUTE_USER_REGISTER:
      case ROUTE_USER_REGISTER_LOCALE:
        return 'register';
      case ROUTE_WELCOME:
      case ROUTE_WELCOME_LOCALE:
      default:
        return 'login';
    }
  }, shallowEqual);
  const dispatch = useDispatch();

  const t = useTranslation();

  return (
    <Track>
      <SkyscraperBackground>
        <DocumentHeader title={t('app.welcome.documentTitle')} titleSuffix="" />
        <WelcomeWrapper>
          <Heading>{t('app.welcome.heading')}</Heading>
          <AuthenticationSelect
            postVerifyRedirectTo={r}
            onChangeType={(type) => {
              if (type === 'login') {
                push(`./welcome${window.location.search}`);
              }
              if (type === 'register') {
                push(`./register${window.location.search}`);
              }
            }}
            current={type}
            onRegisterSuccess={() => {
              trackEvent({
                modifier: 'registration',
                action: 'success',
              });
            }}
            onLoginSuccess={() => {
              const action = getActionFromPath(r);
              if (typeof action !== 'undefined') {
                dispatch(
                  redirect({
                    // typing is complaining when passing the entire action object
                    type: action.type,
                    payload: action.payload,
                    // https://github.com/faceyspacey/redux-first-router/blob/master/src/flow-types.js#L173
                    // https://github.com/faceyspacey/redux-first-router/blob/master/src/pure-utils/pathToAction.js
                    // @ts-expect-error action.meta is an object
                    query: action.query ?? action.meta?.query ?? {},
                  })
                );
              }
            }}
          />
        </WelcomeWrapper>
      </SkyscraperBackground>
    </Track>
  );
};
