import { Row, Column } from '@simplywallst/ui-core';
import type { ReactNode } from 'react';
import {
  Wrapper,
  BackgroundCoverImage,
  Content,
  Body,
  SWSLogo,
} from './styled';

const SkyscraperBackground = ({ children }: { children: ReactNode }) => (
  <Row as="section" height="100vh" width="100vw">
    <Column>
      <Wrapper>
        <BackgroundCoverImage />
        <Content justifyContent="center" height="100vh">
          <Body alignSelf="flex-start">
            <SWSLogo />
            {children}
          </Body>
        </Content>
      </Wrapper>
    </Column>
  </Row>
);

export default SkyscraperBackground;
