import styled from 'styled-components';
import { media } from '@simplywallst/ui-core';

export const WelcomeWrapper = styled('div')`
  text-align: center;
`;

export const Heading = styled('h1')`
  ${({ theme }) => theme.typography.large}
  ${media.mdDown`
    ${({ theme }) => theme.typography.smallStrong}
  `}
  margin-bottom: ${({ theme }) => theme.x3space}rem;
  margin-top: 0;
`;
