import styled, { keyframes } from 'styled-components';
import { media, Row, Column } from '@simplywallst/ui-core';
import LogoBlackSVG from '@assets/images/logo/sws-logo-black.svg';
import {
  getCloudflareImageUrl,
  getStaticUrl,
} from '@/hooks/useCloudflareImage';
export const Wrapper = styled.div`
  overflow: hidden;
  height: 100vh;
  position: relative;
`;

export const Content = styled(Row)`
  position: relative;
  z-index: 1;
  padding: ${({ theme }) => theme.x2spacePx}px;
  overflow: auto;
`;

export const Body = styled(Column)`
  max-width: 380px;
  padding: ${({ theme }) => theme.x2spacePx}px;
  ${media.smUp`
    padding: ${({ theme }) => theme.x5space * 10}px;
  `}
  background: white;
  margin-top: ${({ theme }) => theme.safeAreaInsetTop};
`;

interface BackgroundCoverImageProps {
  isZooming?: boolean;
}

const kenBurning = keyframes`
    100% {
      transform: scale3d(1.3, 1.3, 1.3) translate3d(0px, -12px, 0px);
    }
`;

export const BackgroundCoverImage = styled('div')<BackgroundCoverImageProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  &::before {
    position: absolute;
    display: block;
    content: '';
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(1.2);
    background-size: cover;
    background-position: center;
    background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4QAiRXhpZgAATU0AKgAAAAgAAYKYAAIAAAABAAAAAAAAAAD/2wEEEAANAA0ADQANAA4ADQAOABAAEAAOABQAFgATABYAFAAeABsAGQAZABsAHgAtACAAIgAgACIAIAAtAEQAKgAyACoAKgAyACoARAA8AEkAOwA3ADsASQA8AGwAVQBLAEsAVQBsAH0AaQBjAGkAfQCXAIcAhwCXAL4AtQC+APkA+QFOEQANAA0ADQANAA4ADQAOABAAEAAOABQAFgATABYAFAAeABsAGQAZABsAHgAtACAAIgAgACIAIAAtAEQAKgAyACoAKgAyACoARAA8AEkAOwA3ADsASQA8AGwAVQBLAEsAVQBsAH0AaQBjAGkAfQCXAIcAhwCXAL4AtQC+APkA+QFO/8IAEQgAfQDIAwEiAAIRAQMRAf/EADEAAAMBAQEBAQAAAAAAAAAAAAECAwAEBgUHAQEBAQEBAAAAAAAAAAAAAAABAAIDBP/aAAwDAQACEAMQAAAA82wO0sCjOj07zdqUk9WpGhWrCg3rCmXorz0w9Fed866Kc7410NBst9LF+a4718cwNFlZnZGqjyeq0i9WpF8t689B6Kc1MPTTmfOul+d8a6G52y9Gji8CdvVxxBokFmZDVGmas0Xqzwcb052y9L8z5ep+V866n5Wy9TcrZerc+HyOI9HDEZjhqYoWczNVaJq7QYbtzsPS3M2XqblfOupuVsvU3KR6tzYfh7Dtw2AZgAzZcjFNTmemrRJWMSNzAj0NzsPQ3Mw9J5zl6NDTwDDpxIAYgBGAFNkzPkFUMjVDIjUxI2aDDZoErtAjfR0xAGuRADHKEOXMQBTZNTZNTmemhniqZNNGkSqZGa6eIYByRgmGDYZYIwbDCjl1Nl0sV1OUJOUMuUNPlxf/xAAcEAEBAQEBAQEBAQAAAAAAAAABERICACAwEED/2gAIAQEAAQIA/p48ePHjx48eEREREREbfg8ePHjx48eEREREREbfk8ePHjwiIiIiIiJ1rX2ePCIiIiInR0dHR1rX4HhEREROjo6Ojo61rX4WiIiJ0dHR0dHR0da1922iInR0dHR0dHR1rWvu22260dHWjo61rWvu2223Wta1rWta+rbbbbbrWta1rX1bbbbbbda1rWvwtttttttt182222222222/lbbbbbbbf2tvrbbb/p//8QAFBABAAAAAAAAAAAAAAAAAAAAgP/aAAgBAQADPwBIf//EABoRAQEBAAMBAAAAAAAAAAAAAAARARAgMFD/2gAIAQIBAT8AqqqqrWtbzVVVVVbrelVVVVXtVX4cT0ie3//EABcRAQEBAQAAAAAAAAAAAAAAABEwAGD/2gAIAQMBAT8AocA5m5v/AP/Z);
    filter: blur(16px) brightness(0.8);
  }
  &::after {
    position: absolute;
    display: block;
    content: '';
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-size: cover;
    background-position: center;
    background-image: url(${getCloudflareImageUrl({
      src: getStaticUrl('background-large.jpg'),
      width: 2560,
      quality: 60,
      blur: 10,
    })});
    @media (max-width: 1440px) and (min-width: 1025px) {
      background-image: url(${getCloudflareImageUrl({
        src: getStaticUrl('background-large.jpg'),
        width: 1440,
        blur: 10,
      })});
    }
    @media (max-width: 1024px) and (min-width: 769px) {
      background-image: url(${getCloudflareImageUrl({
        src: getStaticUrl('background-large.jpg'),
        width: 1024,
        fit: 'crop',
        blur: 10,
      })});
    }
    @media (max-width: 768px) {
      background-image: url(${getCloudflareImageUrl({
        src: getStaticUrl('background-large.jpg'),
        width: 768,
        fit: 'crop',
        quality: 30,
        blur: 10,
      })});
    }
    transform-origin: 50% 30%;
    animation: ${kenBurning} 90s linear infinite;
    animation-direction: alternate;
    filter: brightness(0.5) saturate(0.6) contrast(0.9);
  }
`;

export const SWSLogo = styled(LogoBlackSVG)`
  max-width: 160px;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => (theme.x1space + 0.4) * 10}px;
  ${media.mdUp`
    max-width: 180px;
    margin-bottom: ${({ theme }) => theme.x3spacePx}px;
  `}
  display: block;
`;
